// ------------ Hamburger menu ------------

const menuToggle = document.querySelector('.menu-toggle');
const siteNavigation = document.querySelector('.mobil-nav');

menuToggle.addEventListener('click', () => {

    const isOpened = menuToggle.getAttribute('aria-expanded') === "true";
    if (isOpened ? closeMenu() : openMenu());
});

function openMenu () {
    menuToggle.setAttribute('aria-expanded', "true");
    siteNavigation.setAttribute('data-state', "opened")
}
function closeMenu () {
    menuToggle.setAttribute('aria-expanded', "false");
    siteNavigation.setAttribute('data-state', "closed")
}


// ------------ Carousel ------------

let slideIndex = 0;
showSlides();

function showSlides() {
    let i;
    let slides = document.getElementsByClassName("carousel-wrapper");
    for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
    }
    slideIndex++;
    if (slideIndex > slides.length) {slideIndex = 1}
    slides[slideIndex-1].style.display = "block";
    setTimeout(showSlides, 6000);
}